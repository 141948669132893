<template>
  <div class="drawer-notes-info">
    <el-drawer v-model="isInfoShow" title="笔记信息" size="35%" :close-on-click-modal="false">
      <el-form>
        <el-form-item label="标 题" label-width="80px">
          <el-input
            v-model="info.title"
            placeholder="标题"
            size="large"
            clearable
            show-word-limit
            maxlength="200"
          />
        </el-form-item>
        <el-form-item label="目 录" label-width="80px">
          <el-cascader
            v-model="info.prefixCatalog"
            :options="catalogOptions"
            placeholder="请选择文章所属目录"
            style="width: 100%"
            filterable
            clearable
            size="large"
            :props="{
              value: 'serial',
              label: 'title',
              checkStrictly: 'true',
              expandTrigger: 'hover'
            }"
          >
            <template #default="{ node, data }">
              <span>{{ data.title }}</span>
              <span v-if="!node.isLeaf">({{ data.children.length }})</span>
            </template>
          </el-cascader>
          <el-input
            v-model="info.newCatalog"
            placeholder="请输入新建目录名称,多级请用 / 隔开！"
            v-if="infoType === 2"
            style="margin-top: 5px"
            size="large"
            clearable
            show-word-limit
            maxlength="100"
          />
        </el-form-item>
        <el-form-item label="标 签" label-width="80px">
          <el-select
            v-model="info.tags"
            allow-create
            multiple
            :reserve-keyword="false"
            filterable
            clearable
            :multiple-limit="3"
            placeholder="标签"
            size="large"
            style="width: 100%"
          >
            <el-option
              v-for="item in tagOptions"
              :key="item.serial"
              :label="item.tagName"
              :value="item.serial"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="封 面" label-width="80px" v-if="infoType === 1">
          <UploadCropper @uploadImage="uploadImage" :imageShowUrl="info.coverUrl"></UploadCropper>
        </el-form-item>
        <el-form-item label="简 介" label-width="80px">
          <el-input
            v-model="info.intro"
            type="textarea"
            :autosize="{ minRows: 10, maxRows: 20 }"
            placeholder="简介"
            size="large"
            clearable
            show-word-limit
            maxlength="200"
          />
        </el-form-item>
        <div v-if="infoType === 1">
          <el-form-item label="是否评论" label-width="80px">
            <el-radio v-model="info.isComment" label="NO" size="large" border>否</el-radio>
            <el-radio v-model="info.isComment" label="YES" size="large" border>是</el-radio>
          </el-form-item>
          <el-form-item label="是否公开" label-width="80px">
            <el-radio v-model="info.isOpen" label="NO" size="large" border>否</el-radio>
            <el-radio v-model="info.isOpen" label="YES" size="large" border>是</el-radio>
          </el-form-item>
          <el-form-item label="是否置顶" label-width="80px">
            <el-radio v-model="info.isTop" label="NO" size="large" border>否</el-radio>
            <el-radio v-model="info.isTop" label="YES" size="large" border>是</el-radio>
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <div style="flex: auto; text-align: left" v-if="infoType === 1">
          <el-button
            size="large"
            color="#00BFFF"
            style="color: #ffffff"
            :icon="Promotion"
            @click="updateInfo"
          >
            保存修改
          </el-button>
          <el-button
            size="large"
            color="#FF69B4"
            style="color: #ffffff"
            :icon="PictureFilled"
            @click="showImageSelect"
          >
            选择封面
          </el-button>
        </div>
        <div style="flex: auto; text-align: left" v-if="infoType === 2">
          <el-button
            size="large"
            color="#00CED1"
            style="color: #ffffff"
            :icon="Promotion"
            @click="submitInfo"
          >
            提交笔记
          </el-button>
          <el-button
            size="large"
            color="#F4A460"
            style="color: #ffffff"
            :icon="Crop"
            @click="saveInfo"
          >
            保存草稿
          </el-button>
        </div>
      </template>
    </el-drawer>
    <ImageListDialog ref="imageDialog" @selectImage="selectImage"></ImageListDialog>
  </div>
</template>

<script>
import { Crop, PictureFilled, Position, Promotion } from '@element-plus/icons-vue'
import { catalogTree, tags } from '@/api/ArticleApi'
import { imageUpload } from '@/api/ResourceApi'
import UploadCropper from '@/components/upload/UploadCropper'
import ImageListDialog from '@/views/article/components/ImageListDialog'

export default {
  name: 'NotesInfoDrawer',
  components: {
    UploadCropper,
    ImageListDialog
  },
  setup() {
    return {
      Promotion,
      Crop,
      Position,
      PictureFilled
    }
  },
  data() {
    return {
      isInfoShow: false,
      catalogOptions: [],
      tagOptions: [],
      info: {
        serial: '',
        title: '',
        prefixCatalog: '',
        newCatalog: '',
        cover: '',
        coverUrl: '',
        tags: [],
        intro: '',
        isComment: 'NO',
        isOpen: 'NO',
        isTop: 'NO'
      },
      infoType: 1 // 1修改 2新增
    }
  },
  methods: {
    closeDrawer() {
      this.isInfoShow = false
    },
    fillTitle(title) {
      this.info.title = title
      this.infoType = 2
      this.initOptions()
    },
    fillInfo(info) {
      this.info.serial = info.serial
      this.info.title = info.title
      this.info.prefixCatalog = info.prefixCatalog
      this.info.cover = info.cover
      this.info.coverUrl = info.coverUrl
      this.info.tags = info.tags
      this.info.intro = info.intro
      this.info.isComment = info.isComment
      this.info.isOpen = info.isOpen
      this.info.isTop = info.isTop
      this.infoType = 1
      this.isInfoShow = true
      this.initOptions()
    },
    showDrawer() {
      this.infoType = 2
      this.isInfoShow = true
      this.initOptions()
    },
    uploadImage(image) {
      const formData = new FormData()
      formData.append('sourceType', 'COVER')
      formData.append('image', image)
      imageUpload(formData).then((res) => {
        if (res.code === 200) {
          this.info.coverUrl = res.data.imageUrl
          this.info.cover = res.data.serial
        } else {
          this.$message({ message: res.msg, type: 'error' })
        }
      })
    },
    updateInfo() {
      if (this.info.title === '') {
        this.$message({ message: '标题必填！', type: 'warning' })
        return
      }
      if (this.info.prefixCatalog === '' || this.info.prefixCatalog === null) {
        this.$message({ message: '目录必填！', type: 'warning' })
        return
      }
      if (typeof this.info.prefixCatalog === 'object') {
        this.info.prefixCatalog = this.info.prefixCatalog[this.info.prefixCatalog.length - 1]
      }
      this.$emit('updateInfo', this.info)
    },
    submitInfo() {
      if (this.info.title === '') {
        this.$message({ message: '标题必填！', type: 'warning' })
        return
      }
      if (this.info.prefixCatalog === '') {
        this.$message({ message: '目录必填！', type: 'warning' })
        return
      }
      if (typeof this.info.prefixCatalog === 'object') {
        this.info.prefixCatalog = this.info.prefixCatalog[this.info.prefixCatalog.length - 1]
      }
      this.$emit('submitInfo', this.info)
    },
    saveInfo() {
      if (this.info.title === '') {
        this.$message({ message: '标题必填！', type: 'warning' })
        return
      }
      this.$emit('saveInfo', this.info.title)
    },
    showImageSelect() {
      this.$refs.imageDialog.showDialogVisible()
    },
    selectImage(data) {
      this.info.cover = data.serial
      this.info.coverUrl = data.imageUrl
      this.$refs.imageDialog.closeDialogVisible()
    },
    initOptions() {
      catalogTree().then((res) => {
        if (res.code === 200) {
          this.catalogOptions = res.data
        }
      })
      tags().then((res) => {
        if (res.code === 200) {
          this.tagOptions = res.data
        }
      })
    }
  }
}
</script>

<style scoped></style>
