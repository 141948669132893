<template>
  <div class="article-image-list">
    <el-dialog
      v-model="dialogVisible"
      width="75%"
      title="图片列表"
      top="5vh"
      :destroy-on-close="true"
    >
      <ul v-infinite-scroll="loadImagePage" :infinite-scroll-immediate="false" class="image-box">
        <li v-for="data in tableData" :key="data.serial" @click="selectImage($event, data)">
          <el-card shadow="hover" class="image-node">
            <el-image :src="data.imageUrl" fit="cover" />
          </el-card>
        </li>
      </ul>
      <template #footer>
        <span>
          <el-button @click="dialogVisible = false" :icon="CloseBold">放 弃</el-button>
          <el-button type="primary" @click="confirmImage" :icon="Select">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { CloseBold, Select } from '@element-plus/icons-vue'
import { imagePage } from '@/api/ResourceApi'

export default {
  name: 'ImageListDialog',
  setup() {
    return {
      CloseBold,
      Select
    }
  },
  data() {
    return {
      dialogVisible: false,
      current: 1,
      pageSize: 9,
      total: 0,
      tableData: [],
      targetData: null
    }
  },
  methods: {
    showDialogVisible() {
      this.dialogVisible = true
    },
    closeDialogVisible() {
      this.dialogVisible = false
    },
    loadImagePage() {
      if (this.total > this.current * this.pageSize) {
        this.current += 1
        this.queryImagePage()
      }
    },
    selectImage(target, data) {
      Array.from(target.currentTarget.parentElement.children).forEach(function (e) {
        e.firstChild.style.borderColor = '#ffffff'
      })
      target.currentTarget.firstChild.style.borderColor = '#FF00FF'
      this.targetData = data
    },
    confirmImage() {
      this.$emit('selectImage', this.targetData)
    },
    queryImagePage() {
      imagePage({
        pageNum: this.current,
        pageSize: this.pageSize,
        sourceType: 'COVER'
      }).then((res) => {
        if (res.code === 200) {
          this.tableData = this.tableData.concat(res.data.rows)
          this.current = res.data.currentPage
          this.total = res.data.total
        } else {
          this.$message({ message: res.msg, type: 'error' })
        }
      })
    }
  },
  mounted() {
    this.queryImagePage()
  }
}
</script>

<style scoped>
.image-box {
  width: 100%;
  height: 600px;
  margin: 0 auto;
  overflow: scroll;
}

.image-node {
  width: 30%;
  min-width: 450px;
  min-height: 280px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  float: left;
  margin: 5px;
  border: 2px solid #ffffff;
}

.article-image-list >>> .el-image {
  width: 433px;
  height: 269px;
}
</style>
